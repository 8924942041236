<template>
    <v-row class="utente">
        <v-col sm="12">
            <i class="mdi mdi-close-circle-outline close" @click="goRouterBack"/>
        </v-col>
        <v-col sm="12" class="gestione-filters" style="max-width: 600px">
            <v-simple-table>
                <thead>
                <tr>
                    <th class="text-left">Data Specifica</th>
                    <th class="text-left">Data consegna</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        <v-menu v-model="calendarData"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field v-model="viewData" prepend-icon="mdi-calendar" readonly v-on="on"/>
                            </template>
                            <v-date-picker v-model="data" @input="changeData"/>
                        </v-menu>
                    </td>
                    <td>
                        <label class="fancy-checkbox in-form">
                            <input type="checkbox" v-model="check_data_consegna" @change="initSearch">
                            <i class="fas fa-check checked" style="color: #222 !important;"/>
                            <i class="far fa-circle unchecked" style="color: #222 !important;"/>
                        </label>
                    </td>
                </tr>
                </tbody>
            </v-simple-table>
        </v-col>
        <v-col v-if="preseVecchie && preseVecchie.length" sm="12">
            <div>
                <h4>Prese passate non completate</h4>
                <v-simple-table class="content-table">
                    <thead>
                    <tr>
                        <th class="text-left" />
                        <th class="text-left">Data presa</th>
                        <th class="text-left">Cliente</th>
                        <th class="text-left">Bici</th>
                        <th class="text-left">D. Consegna</th>
                        <th class="text-left">Tempo Stimato</th>
                        <th class="text-left">Tempo Reale</th>
                        <th class="text-left">Ordine</th>
                        <th class="text-center"/>
                        <th class="text-center"/>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="presa in preseVecchie" :key="presa._id">
                        <td @click="goToDetail(presa._id)">
                            <b># {{ presa.numero }}</b>
                            <br>
                            <small v-if="presa.tipo === 0 || !presa.tipo">Officina</small>
                            <small v-else-if="presa.tipo === 1">Negozio</small>
                            <small v-else-if="presa.tipo === 2">Online</small>
                            <small v-else-if="presa.tipo === 3">Noleggio / Test</small>
                            <small v-else-if="presa.tipo === 4">Esposizione</small>
                        </td>
                        <td @click="goToDetail(presa._id)"><b>{{ formatDate(presa.viewDataPresa) }}</b></td>
                        <td @click="goToDetail(presa._id)"><b>{{ presa.rNameUser }}</b></td>
                        <td @click="goToDetail(presa._id)"><b>{{ presa.rNameBike }}</b></td>
                        <td @click="goToDetail(presa._id)">{{ presa.viewDataConsegna }}</td>
                        <td @click="goToDetail(presa._id)">{{ presa.tempoPrevisto }}</td>
                        <td @click="goToDetail(presa._id)">{{ presa.ore_reali || '00:00' }}</td>
                        <td @click="goToDetail(presa._id)">
                            <div style="display: flex; align-items: center">
                                <div v-if="presa?.rMontaggioPremium?.tipologia" class="ml-2 mt-1">
                                    <template v-if="presa.rMontaggioPremium.tipologia === 'deluxe'">
                                        <img data-tooltip title="Montaggio deluxe" src="/montaggio-deluxe.svg" width="25" height="25">
                                    </template>
                                    <template v-if="presa.rMontaggioPremium.tipologia === 'standard'">
                                        <img data-tooltip title="Montaggio smart" src="/montaggio-smart.svg" width="25" height="25">
                                    </template>
                                </div>
                                <div class="ml-1">
                                    #{{ presa.rNumeroOrdine }}
                                </div>
                                <div v-if="presa.rSpedizionePremium" class="ml-1">
                                    <i class="fas fa-crown" style="color: #c29436; font-size: 15px !important;"></i>
                                </div>
                            </div>
                        </td>
                        <td class="text-center">
                            <button type="button" name="button" class="button primary">
                                <label class="fancy-checkbox in-form">
                                    <input type="checkbox" v-model="presa.completata" :checked="presa.completata" @change="presaCompletata(presa._id, presa.completata)">
                                    <i class="fas fa-check checked"/>
                                    <i class="far fa-circle unchecked"/>
                                </label>
                                <span>Fatto</span>
                                <span style="display: none;">Da fare</span>
                            </button>
                        </td>
                        <td class="text-center">
                            <button type="button" name="button" class="button primary" style="min-width: unset">
                                <label class="fancy-checkbox in-form">
                                    <input type="checkbox" v-model="presa.sospesa" :checked="presa.sospesa" @change="presaSospesa(presa._id, presa.sospesa)">
                                    <i class="fas fa-lock checked"/>
                                    <i class="fas fa-lock-open unchecked"/>
                                </label>
                            </button>
                        </td>
                        <td>
                            <i v-if="presa.lavaggio" class="fas fa-shower"></i>
                            <a v-if="presa.rPhoneUser" class="ml-2" :href="'https://wa.me/'+presa.rPhoneUser+'?text='+presa.whatsappText" target="_blank">
                                <i class="fab fa-whatsapp"></i>
                            </a>
                            <router-link class="ml-2" :to="{ path: '/presa', query: { _id: presa._id }}">
                                <i class="far fa-edit"></i>
                            </router-link>
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </div>
        </v-col>
        <v-col sm="12" v-for="(date, index) in listDate" :key="index">
            <div>
                <h4>{{ date.viewData }}</h4>
                <v-simple-table class="content-table">
                    <thead>
                    <tr>
                        <th class="text-left" />
                        <th class="text-left">Data presa</th>
                        <th class="text-left">Cliente</th>
                        <th class="text-left">Bici</th>
                        <th class="text-left">D. Consegna</th>
                        <th class="text-left">Tempo Stimato</th>
                        <th class="text-left">Tempo Reale</th>
                        <th class="text-left">Ordine</th>
                        <th class="text-center"/>
                        <th class="text-center"/>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="presa in date.prese" :key="presa._id">
                        <td @click="goToDetail(presa._id)">
                            <b># {{ presa.numero }}</b>
                            <br>
                            <small v-if="presa.tipo === 0 || !presa.tipo">Officina</small>
                            <small v-else-if="presa.tipo === 1">Negozio</small>
                            <small v-else-if="presa.tipo === 2">Online</small>
                            <small v-else-if="presa.tipo === 3">Noleggio / Test</small>
                            <small v-else-if="presa.tipo === 4">Esposizione</small>
                        </td>
                        <td @click="goToDetail(presa._id)"><b>{{ formatDate(presa.viewDataPresa) }}</b></td>
                        <td @click="goToDetail(presa._id)"><b>{{ presa.rNameUser }}</b></td>
                        <td @click="goToDetail(presa._id)"><b>{{ presa.rNameBike }}</b></td>
                        <td @click="goToDetail(presa._id)">{{ presa.viewDataConsegna }}</td>
                        <td @click="goToDetail(presa._id)">{{ presa.tempoPrevisto }}</td>
                        <td @click="goToDetail(presa._id)">{{ presa.ore_reali || '00:00' }}</td>
                        <td @click="goToDetail(presa._id)">
                            <div style="display: flex; align-items: center">
                                <div v-if="presa?.rMontaggioPremium?.tipologia" class="ml-2 mt-1">
                                    <template v-if="presa.rMontaggioPremium.tipologia === 'deluxe'">
                                        <img data-tooltip title="Montaggio deluxe" src="/montaggio-deluxe.svg" width="25" height="25">
                                    </template>
                                    <template v-if="presa.rMontaggioPremium.tipologia === 'standard'">
                                        <img data-tooltip title="Montaggio smart" src="/montaggio-smart.svg" width="25" height="25">
                                    </template>
                                </div>
                                <div class="ml-1">
                                    #{{ presa.rNumeroOrdine }}
                                </div>
                                <div v-if="presa.rSpedizionePremium" class="ml-1">
                                    <i class="fas fa-crown" style="color: #c29436; font-size: 15px !important;"></i>
                                </div>
                            </div>
                        </td>
                        <td class="text-center">
                            <button type="button" name="button" class="button primary">
                                <label class="fancy-checkbox in-form">
                                    <input type="checkbox" v-model="presa.completata" :checked="presa.completata" @change="presaCompletata(presa._id, presa.completata)">
                                    <i class="fas fa-check checked"/>
                                    <i class="far fa-circle unchecked"/>
                                </label>
                                <span>Fatto</span>
                                <span style="display: none;">Da fare</span>
                            </button>
                        </td>
                        <td class="text-center">
                            <button type="button" name="button" class="button primary" style="justify-content: center; min-width: 70px">
                                <label class="fancy-checkbox in-form">
                                    <input type="checkbox" v-model="presa.sospesa" :checked="presa.sospesa" @change="presaSospesa(presa._id, presa.sospesa)">
                                    <i class="fas fa-lock checked"/>
                                    <i class="fas fa-lock-open unchecked"/>
                                </label>
                            </button>
                        </td>
                        <td>
                            <i v-if="presa.lavaggio" class="fas fa-shower"></i>
                            <a v-if="presa.rPhoneUser" class="ml-2" :href="'https://wa.me/'+presa.rPhoneUser+'?text='+presa.whatsappText" target="_blank">
                                <i class="fab fa-whatsapp"></i>
                            </a>
                            <router-link class="ml-2" :to="{ path: '/presa', query: { _id: presa._id }}">
                                <i class="far fa-edit"></i>
                            </router-link>
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </div>
        </v-col>
    </v-row>
</template>
<script>
import {
    momentYMD,
    momentLL,
    compareDateBefore
} from "@/components/js/formatDate";
import apiPresa from '@/components/Presa/js/presa';
import apiLavorazioni from '@/components/Presa/lavorazioni/js/lavorazioni';
import { generalFunction } from "@/components/js/generalFunction";
import { EventBus } from "@/eventBus";

export default {
    name: 'GestioneUtente',
    data() {
        return {
            viewData: momentLL(new Date),
            calendarData: false,
            data: momentYMD(new Date),
            id_operatore: this.$route.query._id,
            allLavorazioni: undefined,
            listDate: [],
            preseVecchie: [],
            check_data_consegna: false
        }
    },
    async created() {
        EventBus.$emit('updateTitleNavbar', 'Lista lavori', this.id_operatore);

        let token = await generalFunction.getToken();
        if (token) {
            let lavorazioni = await apiLavorazioni.getAllLavorazioni(token);
            if (lavorazioni[1])
                this.allLavorazioni = lavorazioni[1];
        }

        await this.initSearch();
    },
    methods: {
        goRouterBack() {
            this.$router.back();
        },
        changeData() {
            this.calendarData = false;
            this.viewData = momentLL(this.data);
            this.initSearch();
        },
        goToDetail(idPresa) {
            this.$router.push({ path: '/lista', query: { _id: idPresa } });
        },
        async initSearch() {
            let field_date = 'data_lavoro';
            if (this.check_data_consegna)
                field_date = 'data_consegna';

            if (this.id_operatore) {
                this.listDate = [];
                let token = await generalFunction.getToken();
                if (token) {
                    let days = 7;
                    let data = {
                        id_operatore: this.id_operatore,
                        data: this.data,
                        days: days
                    };

                    if (this.check_data_consegna)
                        data.check_data_consegna = this.check_data_consegna;

                    this.listDate.push({
                        data: momentYMD(this.data),
                        viewData: momentLL(this.data),
                    });

                    for (let i = 1; i <= days; i ++) {
                        this.listDate.push({
                            data: momentYMD(generalFunction.addDays(new Date(this.data), i)),
                            viewData: momentLL(generalFunction.addDays(new Date(this.data), i))
                        });
                    }

                    let listPrese = await apiPresa.getPreseAssociate(token, data);
                    if (listPrese && listPrese[0]) {
                        let listIdPreseDate = [];
                        let preseVecchie = [];
                        for (let [index, data] of this.listDate.entries()) {
                            data.prese = [];
                            for (let presa of listPrese[0]) {
                                if (
                                    presa.full_ordine &&
                                    presa.full_ordine[0] &&
                                    presa.full_ordine[0].numero
                                ) presa.rNumeroOrdine = presa.full_ordine[0].numero;

                                presa.rSpedizionePremium = false;
                                presa.rMontaggioPremium = false;
                                if (
                                    presa.full_ordine &&
                                    presa.full_ordine[0] &&
                                    presa.full_ordine[0].spedizione_premium
                                ) presa.rSpedizionePremium = presa.full_ordine[0].spedizione_premium;

                                if (
                                    presa.full_ordine &&
                                    presa.full_ordine[0] &&
                                    presa.full_ordine[0].dettagli_ordine &&
                                    presa.full_ordine[0].dettagli_ordine.montaggio_prodotti
                                ) presa.rMontaggioPremium = presa.full_ordine[0].dettagli_ordine.montaggio_prodotti;


                                if (
                                    presa.lavorazioni &&
                                    data.data &&
                                    presa.data_presa &&
                                    data.data === momentYMD(presa[field_date])
                                ) {
                                    presa = await this.controlPresa(presa);
                                    listIdPreseDate.push(String(presa._id));
                                    data.prese.push(presa);
                                }
                            }

                            this.$set(this.listDate, index, data);
                        }

                        for (let presa of listPrese[0]) {
                            if (
                                presa.full_ordine &&
                                presa.full_ordine[0] &&
                                presa.full_ordine[0].numero
                            ) presa.rNumeroOrdine = presa.full_ordine[0].numero;

                            presa.rSpedizionePremium = false;
                            presa.rMontaggioPremium = false;
                            if (
                                presa.full_ordine &&
                                presa.full_ordine[0] &&
                                presa.full_ordine[0].spedizione_premium
                            ) presa.rSpedizionePremium = presa.full_ordine[0].spedizione_premium;

                            if (
                                presa.full_ordine &&
                                presa.full_ordine[0] &&
                                presa.full_ordine[0].dettagli_ordine &&
                                presa.full_ordine[0].dettagli_ordine.montaggio_prodotti
                            ) presa.rMontaggioPremium = presa.full_ordine[0].dettagli_ordine.montaggio_prodotti;

                            if (!listIdPreseDate.includes(String(presa._id))) {
                                presa = await this.controlPresa(presa);
                                listIdPreseDate.push(presa._id);

                                if (
                                    (
                                        presa.data_lavoro &&
                                        this.compareDate(presa.data_lavoro, new Date())
                                    ) || (
                                        presa.data_consegna &&
                                        this.compareDate(presa.data_consegna, new Date())
                                    )
                                ) preseVecchie.push(presa);
                            }
                        }

                        if (preseVecchie.length) {
                            preseVecchie = preseVecchie.sort(function compare(a, b) {
                                let dateA = new Date(a.data_consegna);
                                let dateB = new Date(b.data_consegna);
                                return dateA - dateB;
                            });
                        }

                        this.preseVecchie = preseVecchie;
                    }
                }
            }
        },
        async controlPresa(presa) {
            presa.rNameUser = '';
            presa.tempoPrevisto = '00:00';
            presa.rPhoneUser = undefined;
            presa.whatsappText = undefined;
            if (presa.data_presa)
                presa.viewDataPresa = momentLL(presa.data_presa);

            if (presa.data_consegna)
                presa.viewDataConsegna = momentLL(presa.data_consegna);

            if (
                presa.user &&
                presa.user[0]
            ) {
                if (presa.user[0].nome)
                    presa.rNameUser += presa.user[0].nome;

                presa.whatsappText = encodeURI('Ciao '+presa.rNameUser +', la tua lavorazione è completa, vieni a ritirarla da noi! Lo staff Ciclimattio');

                if (presa.user[0].cognome)
                    presa.rNameUser += ' ' + presa.user[0].cognome;

                if (
                    presa.id_bici &&
                    presa.user[0].dettagli_utente &&
                    presa.user[0].dettagli_utente.bici
                ) {
                    let bike = presa.user[0].dettagli_utente.bici.find(bike => {
                        return bike._id === presa.id_bici;
                    });
                    if (
                        bike &&
                        bike.modello
                    ) presa.rNameBike = bike.modello;
                }

                let telefono = undefined;
                if (presa.user[0].telefono) telefono = presa.user[0].telefono;
                if (
                    !telefono &&
                    presa.user[0].indirizzi_spedizione &&
                    presa.user[0].indirizzi_spedizione.length
                ) {
                    for (let address of presa.user[0].indirizzi_spedizione) {
                        if (
                            !telefono &&
                            address.telefono_corriere_spedizione
                        ) telefono = address.telefono_corriere_spedizione;
                    }
                }

                presa.rPhoneUser = telefono;
            }

            for (let lavorazionePresa of presa.lavorazioni) {
                if (
                    lavorazionePresa.id_lavorazione &&
                    lavorazionePresa.id_valore
                ) {
                    let lavorazione = this.allLavorazioni.find(lavorazione => {
                        return lavorazione._id === lavorazionePresa.id_lavorazione;
                    });

                    if (
                        lavorazione &&
                        lavorazione.dettagli_valore &&
                        lavorazione.dettagli_valore.lavorazioni_officina &&
                        lavorazione.dettagli_valore.lavorazioni_officina.opzioni
                    ) {
                        let option = lavorazione.dettagli_valore.lavorazioni_officina.opzioni.find(option => {
                            return option._id === lavorazionePresa.id_valore;
                        });
                        if (
                            option &&
                            option.tempo_stimato
                        ) presa.tempoPrevisto = generalFunction.sumHours(presa.tempoPrevisto, option.tempo_stimato, ':');
                    }
                }
            }
            return presa;
        },
        async presaCompletata(idPresa, completata) {
            let token = await generalFunction.getToken();
            if (token) {
                if (idPresa) {
                    let data = {
                        id_presa: idPresa,
                        completata: completata,
                    };

                    await apiPresa.completaPresa(token, data);
                }
            }
        },
        async presaSospesa(idPresa, sospesa) {
            let token = await generalFunction.getToken();
            if (token) {
                if (idPresa) {
                    let data = {
                        id_presa: idPresa,
                        sospesa: sospesa,
                    };

                    await apiPresa.presaSospesa(token, data);
                }
            }
        },
        formatDate(date) {
            return momentLL(date)
        },
        compareDate(date1, date2) {
            return compareDateBefore(date1, date2);
        }
    }
}
</script>
