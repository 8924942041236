<template>
    <v-container fluid pl-4 pr-4 class="info-wrapper">
        <v-row v-if="presa" >
            <v-col sm="12" md="11">
                <b># {{ presa.numero }}</b>
                <div style="display: flex; align-items: center">
                    <div class="ml-1">
                        <a v-if="presa.ordine && presa.ordine[0] && presa.ordine[0].numero"
                           :href="'https://kreta.ciclimattio.com/admin/ordini?_id=' + presa.ordine[0]._id"
                           target="_BLANK"
                        >
                            Ordine #{{ presa.ordine[0].numero }}
                        </a>
                    </div>
                    <div v-if="presa?.ordine?.[0]?.dettagli_ordine?.montaggio_prodotti?.tipologia" class="ml-2 mt-1">
                        <template v-if="presa.ordine[0].dettagli_ordine.montaggio_prodotti.tipologia === 'deluxe'">
                            <img data-tooltip title="Montaggio deluxe" src="/montaggio-deluxe.svg" width="25" height="25">
                        </template>
                        <template v-if="presa.ordine[0].dettagli_ordine.montaggio_prodotti.tipologia === 'standard'">
                            <img data-tooltip title="Montaggio smart" src="/montaggio-smart.svg" width="25" height="25">
                        </template>
                    </div>
                    <div v-if="presa?.ordine?.[0]?.spedizione_premium" class="ml-1">
                        <i class="fas fa-crown" style="color: #c29436; font-size: 15px !important;"></i>
                    </div>
                </div>
            </v-col>
            <v-col v-if="!$route.meta.addCol" @click="goRouterBack" sm="12" md="1" class="text-right">
                <i class="far fa-times-circle text-right"></i>
            </v-col>
            <v-col :cols="!$route.meta.addCol ? 6 : 12">
                <v-row>
                    <v-col v-if="presa.user && presa.user[0]" sm="12" md="6">
                        <div class="info-cell">
                            <h6>Cliente</h6>
                            <p>
                                <span v-if="presa.user[0].nome">{{ presa.user[0].nome }}</span> <span v-if="presa.user[0].cognome">{{ presa.user[0].cognome }}<br></span>
                                <span v-if="presa.user[0].telefono">T. {{ presa.user[0].telefono }}<br></span>
                                <span v-if="presa.user[0].email">{{ presa.user[0].email }}</span>
                                <br>
                                <span v-if="presa.ordine && presa.ordine[0] && presa.ordine[0].indirizzo_spedizione">
                                    <label>Indirizzo di spedizione</label>
                                    <br>
                                    <span v-if="presa.ordine[0].indirizzo_spedizione.indirizzo_spedizione">
                                        {{ presa.ordine[0].indirizzo_spedizione.indirizzo_spedizione }}
                                    </span>
                                    <span v-if="presa.ordine[0].indirizzo_spedizione.citta_spedizione">
                                        {{ presa.ordine[0].indirizzo_spedizione.citta_spedizione }}
                                    </span>
                                    <span v-if="presa.ordine[0].indirizzo_spedizione.cap_spedizione">
                                        {{ presa.ordine[0].indirizzo_spedizione.cap_spedizione }}
                                    </span>
                                    <span v-if="presa.ordine[0].indirizzo_spedizione.nazione_spedizione">
                                        {{ presa.ordine[0].indirizzo_spedizione.nazione_spedizione }}
                                    </span>
                                </span>
                            </p>
                        </div>
                    </v-col>
                    <v-col v-if="presa && presa.richieste" sm="12" md="6">
                        <div class="info-cell">
                            <h6>Richieste Cliente</h6>
                            <p>{{ presa.richieste }}</p>
                        </div>
                    </v-col>
                    <v-col v-if="bike" sm="12" md="6">
                        <div class="info-cell">
                            <h6>Bici</h6>
                            <p>
                                <b v-if="bike.rMarca">{{ bike.rMarca }} </b>
                                <span v-if="bike.modello">{{ bike.modello }} <br></span>
                            </p>
                            <span>N. Telaio <i v-if="numero_telaio_loading" class="fas fa-circle-notch fa-spin" style="color: #222; font-size: 12px !important;"></i></span>
                            <v-text-field v-model="numero_telaio" @change="updateNumeroTelaio(presa.id_utente, bike._id)"/>
                        </div>
                    </v-col>
                    <v-col v-if="presa && presa.richieste" sm="12" md="6">
                        <div class="info-cell">
                            <h6>Note Interne</h6>
                            <p>{{ presa.note_interne }}</p>
                        </div>
                    </v-col>
                    <v-col v-if="presa" cols="12">
                        <h6>Note Lavorazione</h6>
                        <v-textarea v-model="noteLavorazione" solo :value="presa.note_lavorazione" name="input-7-4"/>
                    </v-col>
                    <v-col v-if="presa" cols="12" md="6">
                        <span>Tempo Stimato</span>
                        <input type="time" v-model="orePreviste" class="timePicker" min="00:00" max="99:00" disabled>
                        <br>
                        <span>Tempo Reale</span>
                        <input type="time" v-model="oreReali" class="timePicker" @change="changeOreReali()" min="00:00" max="99:00" required>
                    </v-col>
                    <!--v-col cols="12" md="6" v-if="presa">
                        <span>Manodopera</span>
                        <v-text-field v-model="manodopera" type="number" placeholder="Inserisci un numero" :loading="isLoadingManodopera">
                            <template v-slot:prepend-inner>
                                <v-icon style="font-size: 16px !important;" class="mt-1 mr-1">fas fa-euro-sign</v-icon>
                            </template>
                        </v-text-field>
                    </v-col-->
                    <v-col v-if="presa" cols="12">
                        <div class="text-right">
                            <v-btn rounded color="secondary" class="save" @click="apriPagina(rConfig.domainAdmin + '/admin/prese/stampa?_id='+presa._id)">
                                <i class="fas fa-print" style="color: white"></i>
                            </v-btn>
                            <v-btn rounded color="primary" class="save" @click="salvaNoteTempoPresa()">Salva</v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-col>
            <v-col sm="12" :md="!$route.meta.addCol ? '6' : '12'" >
                <LavorazioneSummary :presa="presa" ref="lavorazioneSummary"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col sm="12" md="12">
                <v-simple-table class="info-order"
                                v-for="(category, index) in categories"
                                :key="index"
                                v-show="selectedLavorazioni.find(option => {
                                    return option.rCategoria === category.value;
                                })">
                    <thead>
                    <tr>
                        <th class="text-left">{{ category.nome }}</th>
                        <th class="text-left"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="lavorazione in selectedLavorazioni"
                        :key="lavorazione._id"
                        v-show="lavorazione.rCategoria === category.value"
                    >
                        <td>
                            {{ lavorazione.rName }}
                            <span v-if="lavorazione.rOption && lavorazione.rOption.rName">- {{ lavorazione.rOption.rName }}</span>
                        </td>
                        <td class="text-right" v-if="lavorazione.rOption && lavorazione.rOption.rTempoStimato && lavorazione.rOption.rTempoStimato !== '00:00'">
                            Tempo Stimato {{ lavorazione.rOption.rTempoStimato }} h
                        </td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
            <!--v-col v-if="presa && presa.articoli && presa.articoli.length > 0" sm="12" md="12">
                <v-simple-table>
                    <thead>
                    <tr>
                        <th class="text-left">Articolo</th>
                        <th class="text-left">Marca</th>
                        <th class="text-left">Quantit&agrave;</th>
                        <th class="text-left">Codice Interno</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(articolo, index) in presa.articoli" :key="articolo._id">
                        <td><b>{{ articolo.nome }}</b></td>
                        <td v-for="brand in brands" :key="brand._id+index" v-show="articolo.marca && brand._id === articolo.marca">
                            <span v-if="brand._id === articolo.marca">{{ brand.rName }}</span>
                        </td>
                        <td v-if="!articolo.marca"></td>
                        <td>{{ articolo.quantita }}</td>
                        <td>{{ articolo.codice }}</td>
                    </tr>
                    </tbody>
                </v-simple-table>
            </v-col-->
            <v-col v-if="presa && presa.ordiniOfficina && presa.ordiniOfficina.length > 0" sm="12" md="12">
                <v-simple-table>
                    <thead>
                    <tr>
                        <th class="text-left">Articolo</th>
                        <th class="text-left">Marca</th>
                        <th class="text-left">Quantit&agrave;</th>
                        <th class="text-left">Codice Interno</th>
                        <th class="text-left">
                            Controllato
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="ordineOfficina in presa.ordiniOfficina">
                        <tr v-for="articolo in ordineOfficina.prodotti" :key="articolo._id">
                            <td>
                                <b v-if="articolo.realProduct && articolo.realProduct.rNameProduct">{{ articolo.realProduct.rNameProduct }}</b>
                                <br>
                                <span v-if="articolo.realProduct && articolo.realProduct.rNameGroup">
                                    {{ articolo.realProduct.rNameGroup }}
                                    <br>
                                </span>
                                <span v-if="articolo.realProduct && articolo.realProduct.rNameVariation">
                                    {{ articolo.realProduct.rNameVariation }}
                                </span>
                            </td>
                            <td>
                                <span v-if="articolo.realProduct && articolo.realProduct.rNameBrand">{{ articolo.realProduct.rNameBrand }}</span>
                            </td>
                            <td>{{ articolo.quantita }}</td>
                            <td>{{ articolo.codice }}</td>
                            <td>
                                <label class="fancy-checkbox in-form">
                                    <input type="checkbox" v-model="articolo.controllato" @change="aggiornaPezzoOfficina(ordineOfficina._id, articolo)">
                                    <i class="fas fa-check-circle checked"/>
                                    <i class="far fa-circle unchecked"/>
                                </label>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </v-simple-table>
            </v-col>
            <v-col v-if="presa" cols="12" class="pt-0 pb-5">
                <div class="text-right"><v-btn rounded color="" class="save" :to="'/presa?&_id='+presa._id+'&tab=1'">Aggiungi Prodotto</v-btn></div>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import LavorazioneSummary from '@/components/gestione/LavorazioneSummary';
import apiPresa from '@/components/Presa/js/presa';
import apiLavorazioni from '@/components/Presa/lavorazioni/js/lavorazioni';
import { generalFunction } from "@/components/js/generalFunction";
import { EventBus } from "@/eventBus";
import config from "@/config/config";

export default {
    name: 'ListaLavori',
    components: { LavorazioneSummary },
    data() {
        return {
            idPresa: this.$route.query._id,
            presa: undefined,
            bike: undefined,
            numero_telaio: undefined,
            numero_telaio_loading: false,
            categories: [
                { nome: 'Cura', value: 'cura' },
                { nome: 'Telaio', value: 'telaio' },
                { nome: 'Impianto frenante', value: 'impianto_frenante' },
                { nome: 'Trasmissione', value: 'trasmissione' },
                { nome: 'Ruote', value: 'ruote' },
                { nome: 'Esterne', value: 'esterna' },
            ],
            allLavorazioni: [],
            selectedLavorazioni: [],
            brands: [],
            noteLavorazione: '',
            oreReali: '',
            viewOreReali: false,
            orePreviste: '',
            manodopera: '',
            isLoadingManodopera: false,
            timeoutChangeManodopera: undefined,
        }
    },
    computed: {
        rConfig() {
            return config;
        }
    },
    async created() {
        let brands = [] = await generalFunction.getBrands();
        if (brands[1]) {
            for (let brand of brands[1]) {
                brand.rName = 'Senza Nome';
                if (
                    brand &&
                    brand.it &&
                    brand.it.nome
                ) brand.rName = brand.it.nome;
            }
        }
        this.brands = brands[1];

        this.initPresa();

        EventBus.$on('reloadDetailPresa', () => {
            this.initPresa();
        });

        let token = await generalFunction.getToken();
        if (token) {
            let lavorazioni = await apiLavorazioni.getAllLavorazioni(token);
            if (lavorazioni[1]) {
                for (let lavorazione of lavorazioni[1]) {
                    lavorazione.rName = 'Senza nome';
                    if (lavorazione.it && lavorazione.it.nome)
                        lavorazione.rName = lavorazione.it.nome;

                    lavorazione.rCategoria = undefined;
                    lavorazione.rOptions = [];
                    if (
                        lavorazione.dettagli_valore &&
                        lavorazione.dettagli_valore.lavorazioni_officina
                    ) {
                        if (lavorazione.dettagli_valore.lavorazioni_officina.categoria)
                            lavorazione.rCategoria = lavorazione.dettagli_valore.lavorazioni_officina.categoria;

                        if (lavorazione.dettagli_valore.lavorazioni_officina.opzioni) {
                            for (let opzione of lavorazione.dettagli_valore.lavorazioni_officina.opzioni) {
                                opzione.id_lavorazione = lavorazione._id;
                                opzione.rName = 'Senza nome';
                                if (opzione.nome) opzione.rName = opzione.nome;
                            }
                            lavorazione.rOptions = lavorazione.dettagli_valore.lavorazioni_officina.opzioni;
                        }
                    }
                }
            }
            this.allLavorazioni = lavorazioni[1];
        } else alert('Spiacenti si è verificato un errore');

        await this.controlLavorazioniPresa();
    },
    mounted() {
        if(
            this.presa &&
            !this.presa['manodopera']
        ) {
            let those = this;
            setTimeout(() => {
                if(
                    those.$refs.lavorazioneSummary &&
                    those.$refs.lavorazioneSummary.manodopera
                ) those.manodopera = those.$refs.lavorazioneSummary.manodopera;
            }, 1000);
        }
    },
    methods: {
        goRouterBack() {
            this.$router.back();
        },
        async initPresa() {
            let token = await generalFunction.getToken();
            if (
                token &&
                this.idPresa
            ) {
                let presa = await apiPresa.getPresaWithDetails(token, this.idPresa);
                console.log(presa)
                if (presa) {
                    if (
                        presa.user &&
                        presa.user[0]
                    ) {
                        if (
                            presa.id_bici &&
                            presa.user[0].dettagli_utente &&
                            presa.user[0].dettagli_utente.bici
                        ) {
                            let bike = presa.user[0].dettagli_utente.bici.find(bike => {
                                return bike._id === presa.id_bici;
                            });
                            if(bike['marca']) {
                                let rMarca = this.brands.find(brand => {
                                    return brand._id === bike['marca'];
                                });
                                if(rMarca)
                                    bike['rMarca'] = rMarca['rName'];
                            }

                            if (bike) {
                                if (bike.numero_telaio)
                                    this.numero_telaio = bike.numero_telaio;
                                this.bike = bike;
                            }
                            else this.bike = {};
                        }
                    }
                    this.noteLavorazione = presa['note_lavorazione'];
                    this.oreReali = presa['ore_reali'];
                    this.orePreviste = presa['tempo_previsto'];
                    this.manodopera = presa['manodopera'];
                } else presa = {};
                this.presa = presa;
            }
        },
        controlLavorazioniPresa() {
            let selectedLavorazioni = [];
            if (
                this.presa &&
                Object.entries(this.presa).length > 0
            ) {
                if (
                    this.presa.lavorazioni &&
                    this.presa.lavorazioni.length > 0
                ) {
                    for (let lavorazionePresa of this.presa.lavorazioni) {
                        let addLavorazione = {};
                        if (
                            lavorazionePresa.id_lavorazione &&
                            lavorazionePresa.id_valore
                        ) {
                            let lavorazione = this.allLavorazioni.find(lavorazione => {
                                return lavorazione._id === lavorazionePresa.id_lavorazione;
                            });

                            if (
                                lavorazione &&
                                lavorazione.dettagli_valore &&
                                lavorazione.dettagli_valore.lavorazioni_officina &&
                                lavorazione.dettagli_valore.lavorazioni_officina.opzioni
                            ) {
                                addLavorazione.rName = lavorazione.rName;
                                addLavorazione.rCategoria = lavorazione.rCategoria;
                                addLavorazione.rOption = {};
                                let option = lavorazione.dettagli_valore.lavorazioni_officina.opzioni.find(option => {
                                    return option._id === lavorazionePresa.id_valore;
                                });
                                if (option) {
                                    addLavorazione.rOption.rName = option.rName;

                                    let tempoStimato = '00:00';
                                    if (option.tempo_stimato)
                                        tempoStimato = generalFunction.sumHours(tempoStimato, option.tempo_stimato, ':');
                                    addLavorazione.rOption.rTempoStimato = tempoStimato;
                                }
                            }
                        }
                        selectedLavorazioni.push(addLavorazione);
                    }
                }
            }
            this.selectedLavorazioni = [ ...selectedLavorazioni ];
        },
        async salvaNoteTempoPresa() {
            let token = await generalFunction.getToken();
            if (
                token &&
                this.idPresa
            ) {
                let data = {
                    _id: this.idPresa,
                    note: this.noteLavorazione,
                    ore_reali: this.oreReali,
                    manodopera: this.manodopera,
                }
                await apiPresa.salvaNoteTempo(token, data);
            }
        },
        async changeOreReali() {
            clearTimeout(this.timeoutChangeManodopera);
            let those = this;
            this.timeoutChangeManodopera = setTimeout(function () {
                if(those.$refs.lavorazioneSummary) {
                    those.isLoadingManodopera = true;
                    let realManodopera = those.$refs.lavorazioneSummary.realManodopera;
                    let tempoStimato = those.$refs.lavorazioneSummary.tempoStimato;
                    let minutiReali = (parseInt(those.oreReali.split(':')[0]) * 60) + parseInt(those.oreReali.split(':')[1]);
                    let minutiStimati = (parseInt(tempoStimato.split(':')[0]) * 60) + parseInt(tempoStimato.split(':')[1]);
                    let manodoperaReale = parseFloat((realManodopera * minutiReali) / minutiStimati).toFixed(2);
                    setTimeout(() => {
                        those.manodopera = manodoperaReale;
                        those.isLoadingManodopera = false;
                    }, 2000);
                }
            }, 1000);
        },
        apriPagina(url) {
            window.open(url)
        },
        async updateNumeroTelaio(id_utente, id_bici) {
            if (
                id_utente &&
                id_bici
            ) {
                if (this.numero_telaio) {
                    let token = await generalFunction.getToken();
                    if (
                        token &&
                        this.idPresa
                    ) {
                        this.numero_telaio_loading = true;
                        let data = {
                            id_utente: id_utente,
                            id_bici: id_bici,
                            numero_telaio: this.numero_telaio
                        }
                        await apiPresa.salvaNumeroTelaio(token, data);
                        this.numero_telaio_loading = false;
                    }
                } else alert("Inserisci il numero di telaio!");
            } else alert("Spiacenti si è verificato un errore, verifica l'utente e la sua bici");
        },
        async aggiornaPezzoOfficina(idOrdine, articolo) {
            let token = await generalFunction.getToken();
            if (
                token &&
                idOrdine &&
                articolo._id
            ) {
                let data = {
                    id_ordine: idOrdine,
                    id_prodotto: articolo._id,
                    controllato: articolo.controllato
                }
                await apiPresa.aggiornaProdottoOrdine(token, data);
            }
        }
    }
}
</script>
